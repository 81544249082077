'use client'

import { createContext, useContext, useMemo } from 'react'
import {
  type FeatureFlags,
  type Features,
} from '@/lib/data/get-cached-features'

export const enum AvailableFlags {
  ForceSignUpFlow = 'force-sign-up-flow',
  SignUpFlowIncludeBrands = 'sign-up-flow-include-brands',
  OnlineOrdering = 'online-ordering',
  EcommCheckout = 'ecomm-checkout',
  BetaLaunch = 'beta-launch',
  KeylightAds = 'keylight-ads',
  personalizationProducts = 'personalization-products',
  KeylightBanner = 'keylight-banner',
  TrendingNowProductGroups = 'trendingnow-productgroups',
  TrendingNowProducts = 'trendingnow-products',
  riskified = 'riskified',
  ApplianceOnlineStateInventory = 'appliance-online-state-inventory',
}

interface FeatureFlagsContProps {
  features?: Features
}

const FeatureFlagContext = createContext<FeatureFlagsContProps>({})

function useFeatureFlagsClient() {
  const { features } = useContext(FeatureFlagContext)

  const isEnabled = (flagName: FeatureFlags) => {
    if (features == null) {
      return false
    }
    return features[flagName]
  }

  return { isEnabled }
}

export const useFeatures = () => useContext(FeatureFlagContext)

// this useFlagIsEnabled returns boolean, so its not the same as consuming useFlag directly
export function useIsFlagEnabledClient(flagName: AvailableFlags) {
  const { isEnabled } = useFeatureFlagsClient()
  return isEnabled(flagName)
}

export const FeatureFlagsProvider = ({
  children,
  features,
}: {
  children: React.ReactNode
  features: Features
}) => {
  const value = useMemo(
    () => ({
      features,
    }),
    [features],
  )

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  )
}
